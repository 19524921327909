// @flow

import React, { useState, useEffect, useMemo } from "react";
import AceEditor from "react-ace";
import urlJoin from "url-join";

import Box from "@material-ui/core/Box";
import Toolbar from "@material-ui/core/Toolbar";
import { CircularProgress } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import NavigateBefore from "@material-ui/icons/NavigateBefore";
import NavigateNext from "@material-ui/icons/NavigateNext";

import { setIn, without } from "seamless-immutable";
import useEventCallback from "use-event-callback"; // npm package = useCallback react hook
import classnames from "classnames";
import { HotKeys } from "react-hotkeys";
import Header from "../Header";
import EditableTitleText from "./EditableTitleText";
import SamplesView from "../SamplesView";
import InterfacePage from "../InterfacePage";
import EditSampleDialog from "../EditSampleDialog";
import useTimeToCompleteSample from "../../utils/use-time-to-complete-sample";
import { useToasts } from "../Toasts";
import LabelView from "../LabelView";
import useIsLabelOnlyMode from "../../utils/use-is-label-only-mode";
import { useHotkeyStorage } from "../HotkeyStorage";
import { useStyles } from "./Styles";
import { WorkspaceToolContext } from "../../providers/workspaceToolContext";
import { getSubscriptionDetails } from "../../utils/backend";

import "brace/mode/javascript";
import "brace/theme/github";

import { MySnackBar } from "../helpers/material_ui_helpers/MySnackBar";
import { configurationGuard } from "../../utils/configuration-guard/configuration-guard";
import { rtdb, firestore } from "../../firebase/firebase";
import { getQueryParams } from "../../utils/getQueryParams";
import { NavStripButton } from "../Styled";
import styles from './arrows.module.css'

import { UserContext } from "../UserContext";
import { LoadingContext } from "../LoadingContext";
import { templates } from "../StartingPage/templates";

const mlTaskToTemplateName = {
    image_segmentation: "Detection",
    image_pixel_segmentation: "Segmentation"
};

const DatasetEditor = ({
    file,
    dataset,
    inSession,
    url,
    fileName = window.location.href.split("id=")[1],
    onChangeDataset: onChangeDatasetProp = () => null, // what is its use /
    onChangeFile,
    initialMode,
    authConfig,
    selectedBrush,
}) => {
    const labelOnlyMode = useIsLabelOnlyMode();
    const [valueDisplay, setValueDisplay] = useState(fileName);
    const c = useStyles();
    const { addToast } = useToasts();
    const [singleSampleDataset, setSingleSampleDataset] = useState();
    const [sampleInputEditor, changeSampleInputEditor] = useState({});
    const [jsonText, changeJSONText] = useState();
    const { ipcRenderer } = {};
    // const posthog = usePosthog()

    const [sampleTimeToComplete, changeSampleTimeToComplete] = useTimeToCompleteSample();

    // open booleans for snackBars
    const [chooseLabelOpen, setChooseLabelOpen] = useState(false);
    const [minLabelsOpen, setMinLabelsOpen] = useState(false);
    const [answerLabelingQuestion, setAnswerLabelingQuestion] = useState(false);
    const [dublicateLabels, setDuplicateLabels] = useState(false);

    const [workspaceTool, setWorkspaceTool] = useState(null);

    const [backCallback, setBackCallback] = useState(null);
    const [user, setUser] = useState(null);

    const [userState, setUserState] = useState("unsuspended");
    const [subscriptionDetails, setSubscriptionDetails] = useState(null);
    const [userSpecialStatus, setUserSpecialStatus] = useState(null);

    // ENABLE IMAGE POINTS
    // const [insufficientImagePointsError, setInsufficientImagePointsError] = useState(false);

    const [loading, setLoading] = useState(false);

    const { uid, pid, type: projectType } = getQueryParams();
    const projectRef = rtdb.ref(`${uid}/${pid}`);

    // Set the header tabs
    let headerTabs = ["configuration", "upload", "label"];
    if (projectType === "auto_annotation") {
        headerTabs = ["label"];
    } else if (labelOnlyMode) {
        headerTabs = ["Label"];
    }

    initialMode = initialMode ?? headerTabs[0];
    const [mode, setMode] = useState(initialMode);
    const [projectStatus, setProjectStatus] = useState({ iface: { multiple: null }, mode: initialMode });

    dataset = dataset || {}; // the case after || should never happen
    if (!dataset.samples) {
        dataset = setIn(dataset, ["samples"], []);
        onChangeDatasetProp(dataset);
    }

    const loadUserData = async () => {
        // Load user data
        let user = await firestore.doc(`users/${uid}`).get();
        user = user.data();
        // ENABLE IMAGE POINTS
        // user.imagePointDetails = await getImagePointDetails(uid);
        user.uid = uid;

        setUser(user);
        return user;
    }

    const saveProjectStatus = async (status, datasetToSave=dataset) => {
        setLoading(true);
        if (!status) status = projectStatus;
        await projectRef.child("ml_task").set(datasetToSave.interface.type);
        await projectRef.child("status").set(status);
        if (projectType === "auto_annotation")
            await projectRef.child("dataset").set(status.samples);
        setLoading(false);
    };

    const loadProjectStatus = async () => {
        setLoading(true);
        // Load user data
        let user = await loadUserData();

        // TODO: Move everything into loadUserData();
        const subscription = await getSubscriptionDetails(uid);

        setSubscriptionDetails(subscription);
        setUserState(user.state);
        setUserSpecialStatus(user.special_status);

        if ((user.special_status === "Admin" || user.special_status === "Developer" || user.special_status === "Beta")
            || (subscription.plan !== null && (subscription.status === "trialing" || subscription.status === "active"))) {
            if (user.state !== "suspended") {
                // TODO: Use the disabled property from firebase auth
                const projectData = await (await projectRef.get()).val();
                let status = projectData.status;
                let mlTask = projectData.ml_task;
                setLoading(false);

                let updatedDataset = dataset;
                if (projectType === "auto_annotation") {
                    if (status === "annotated") {
                        // Initial project status for auto annotation projects
                        const template = templates.find((t) => t.name === mlTaskToTemplateName[mlTask]);
                        const iface = {...template.dataset.interface};
                        iface.labels = projectData.labels.map(label => ({ id: label }));
                        status = { mode: "label", iface, samples: projectData.dataset };
                        updatedDataset = setIn(dataset, ["samples"], projectData.dataset);
                        updatedDataset = setIn(updatedDataset, ["interface"], status.iface);
                        await saveProjectStatus(status, updatedDataset);
                    } else {
                        updatedDataset = setIn(dataset, ["samples"], status.samples);
                        updatedDataset = setIn(updatedDataset, ["interface"], status.iface);
                    }
                }

                if (!status || status === "init") status = {};
                if (!status.iface) status.iface = { type: null };
                if (!status.iface.hasOwnProperty("multiple")) status.iface.multiple = null;

                if (!status.mode) {
                    if (projectType === "auto_annotation")
                        status.mode = "label";
                    else
                        status.mode = "configuration";
                }

                setProjectStatus(status);
                if (projectType === "auto_annotation")
                    onChangeDataset(updatedDataset);

                setMode(status.mode);

                // Make sure labeling tool is the last page
                const lastRef = projectRef.child("last");
                const last = await (await lastRef.get()).val();
                if (last !== window.location.href) await lastRef.set(window.location.href);
            }
        } else {
            window.location = urlJoin(process.env.REACT_APP_IAM, "billing");
        }

        setLoading(false);
    };

    const setters = {
        setChooseLabelOpen,
        setMinLabelsOpen,
        setAnswerLabelingQuestion,
        setDuplicateLabels,
    };

    const changeMode = (mode) => {
        // ENABLE IMAGE POINTS
        // Prevent user from going to label tab if they don't have enough image points
        // if (mode === "label") {
        //     const datasetSamples = dataset.samples || [];
        //     const totalSamplesCount = datasetSamples.length
        //     const labeledSamplesCount = datasetSamples.filter((s) => s.pointConsumed);
        //     const unlabeledSamplesCount = totalSamplesCount - labeledSamplesCount;
        //     if (unlabeledSamplesCount > user.imagePointDetails.total) {
        //         setInsufficientImagePointsError(true);   
        //         return;
        //     }
        // }

        setMode(mode);
        if (configurationGuard(projectStatus, setters, mode, setMode)) {
            const status = setIn(projectStatus, ["mode"], mode);
            setProjectStatus(status);
            saveProjectStatus(status);
        }
        setWorkspaceTool(null);
    };

    const onChangeDataset = async (newDataset) => {
        if (newDataset) {
            onChangeDatasetProp(newDataset);
            const status = {
                ...projectStatus,
                iface: newDataset.interface,
                samples: newDataset.samples || null,
            };
            setProjectStatus(status);
        }
    };

    const asyncLoad = async () => {
        await loadProjectStatus();
    };

    useEffect(() => {
        asyncLoad();
    }, []);

    useEffect(() => {
        // for electron stuff.
        if (!ipcRenderer) return;
        const onOpenSettingsPage = () => changeMode("settings");
        const onOpenSamplesPage = () => changeMode("samples");
        const onOpenLabelPage = () => changeMode("label");
        ipcRenderer.on("open-settings-page", onOpenSettingsPage);
        ipcRenderer.on("open-samples-page", onOpenSamplesPage);
        ipcRenderer.on("open-label-page", onOpenLabelPage);
        return () => {
            ipcRenderer.removeListener(
                "open-settings-page",
                onOpenSettingsPage,
            );
            ipcRenderer.removeListener("open-samples-page", onOpenSamplesPage);
            ipcRenderer.removeListener("open-label-page", onOpenLabelPage);
        };
    }, [ipcRenderer]);

    useEffect(() => {
        if (mode === "json") {
            changeJSONText(JSON.stringify(dataset, null, "  "));
        }
        if (mode !== "label") {
            setSingleSampleDataset(null);
        }
        // posthog.capture("open_editor_tab", { tab: mode })
    }, [mode, changeJSONText, dataset]);

    const onChangeTab = useEventCallback((tab) => changeMode(tab.toLowerCase()));

    const onBack = () => {
        const currentIndex = headerTabs.findIndex((tab) => tab === mode);
        if (mode === "label" && singleSampleDataset) {
            // On Labeling a Sample
            if (backCallback !== null && !backCallback.cb()) return;
            setSingleSampleDataset(null);
        } else if (currentIndex === 0) {
            if (projectType === "auto_annotation") {
                window.location.replace(`${process.env.REACT_APP_IAM}AutoAnnotation?id=${pid}`);
            } else {
                // In configuration interface
                setProjectStatus(setIn(projectStatus, ["iface"], {}));
            }
        } else onChangeTab(headerTabs[currentIndex - 1]);
    };

    const onNext = () => {
        const currentIndex = headerTabs.findIndex((tab) => tab === mode);
        if (currentIndex !== headerTabs.length - 1) onChangeTab(headerTabs[currentIndex + 1]);
    };

    const shortcutHandlers = useMemo(
        () => ({
            switch_to_label: () => changeMode("label"),
            switch_to_setup: () => changeMode("setup"),
            switch_to_samples: () => changeMode("samples"),
        }),
        [changeMode],
    );

    const { keyMap } = useHotkeyStorage();

    return (
        <LoadingContext.Provider
            value={{loading, setLoading}}
        >
        <UserContext.Provider
            value={user}
        >
            {loading ? (
                <center style={{ marginTop: "25%" }}>
                    <CircularProgress />
                </center>) : (
                <>
                    <MySnackBar
                        open={chooseLabelOpen}
                        setOpen={setChooseLabelOpen}
                        message="please choose the labeling type first"
                        severity="info"
                        hideDuration={3000}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "center",
                        }}
                    />

                    <MySnackBar
                        open={minLabelsOpen}
                        setOpen={setMinLabelsOpen}
                        message="You have to enter at least 2 labels"
                        severity="error"
                        hideDuration={3000}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "center",
                        }}
                    />

                    <MySnackBar
                        open={answerLabelingQuestion}
                        setOpen={setAnswerLabelingQuestion}
                        message="You have to Set Allow Multiple Classification per Image configuration"
                        severity="error"
                        hideDuration={3000}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "center",
                        }}
                    />

                    <MySnackBar
                        open={dublicateLabels}
                        setOpen={setDuplicateLabels}
                        message="Labels have to be Different"
                        severity="error"
                        hideDuration={3000}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "center",
                        }}
                    />

                    {/* ENABLE IMAGE POINTS */}
                    {/* <MySnackBar
                        open={insufficientImagePointsError}
                        setOpen={setInsufficientImagePointsError}
                        message={`Insufficient image points, visit ${urlJoin(process.env.REACT_APP_IAM, 'billing')}`}
                        severity="error"
                        hideDuration={4000}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "center",
                        }}
                    /> */}

                    {
                        userState === "suspended" && (
                            <>
                                <Header />
                                <center>
                                    <h1>You are suspended</h1>
                                </center>
                            </>
                        )
                    }
                    {
                        (userState !== "suspended"
                            && ((userSpecialStatus === "Admin" || userSpecialStatus === "Developer" || userSpecialStatus === "Beta") ||
                                (subscriptionDetails !== null
                                    && subscriptionDetails.plan !== null
                                    && (subscriptionDetails.status === "trialing"
                                        || subscriptionDetails.status === "active"))))
                        && (
                            <HotKeys allowChanges handlers={shortcutHandlers} keyMap={keyMap}>
                                <div className={classnames(c.container, "universaldatatool")}>
                                    <Header
                                        title={
                                            inSession ? (
                                                <TextField
                                                    label="Share Link"
                                                    title="share-link"
                                                    value={url}
                                                    variant="outlined"
                                                    size="small"
                                                />
                                            ) : (
                                                <EditableTitleText
                                                    label="File Name"
                                                    onChange={(newName) => {
                                                        onChangeFile(
                                                            setIn(file, ["fileName"], newName),
                                                        );
                                                        setValueDisplay(newName);
                                                    }}
                                                    value={valueDisplay || ""}
                                                />
                                            )
                                        }
                                        onChangeTab={onChangeTab} // change the mode type
                                        currentTab={mode}
                                        tabs={headerTabs}
                                    />
                                    <div style={{ height: "100%", overflowY: "scroll" }}>
                                        {mode === "json" && (
                                            <AceEditor
                                                theme="github"
                                                mode="javascript"
                                                width="100%"
                                                value={jsonText || ""}
                                                editorProps={{ $blockScrolling: Infinity }}
                                                onChange={(t) => changeJSONText(t)}
                                            />
                                        )}
                                        {mode === "configuration" && (
                                            <InterfacePage
                                                dataset={dataset}
                                                onClearLabelData={() => {
                                                    // this is not used in the interfacePage
                                                    onChangeDataset(
                                                        setIn(
                                                            dataset,
                                                            ["samples"],
                                                            dataset.samples.map((s) => without(s, "annotation")),
                                                        ),
                                                    );
                                                }}
                                                projectStatus={projectStatus}
                                                onChange={(iface) => {
                                                    // this is crucial to what I am doing.
                                                    if (
                                                        // detect change in the choice.
                                                        iface.type !== dataset.interface.type
                                                        && dataset.interface.type !== "empty"
                                                        && dataset.samples
                                                            .map((s) => s.annotation)
                                                            .some(
                                                                Boolean,
                                                            ) /** what is annotation */
                                                    ) {
                                                        addToast(
                                                            "Changing label types can cause label data issues. You must clear all label data first.",
                                                            "error",
                                                        );
                                                        return;
                                                    }

                                                    /** what does this function does !!??? */
                                                    onChangeDataset({
                                                        ...dataset,
                                                        interface: iface,
                                                    });
                                                }}
                                            />
                                        )}
                                        {mode === "upload" && (
                                            <SamplesView
                                                file={file}
                                                dataset={dataset}
                                                projectStatus={projectStatus}
                                                saveProjectStatus={saveProjectStatus}
                                                openSampleLabelEditor={(sampleIndex) => {
                                                    setSingleSampleDataset({
                                                        ...dataset,
                                                        samples: [dataset.samples[sampleIndex]],
                                                        sampleIndex,
                                                        annotationStartTime: Date.now(),
                                                    });
                                                    changeMode("label");
                                                }}
                                                openSampleInputEditor={(sampleIndex) => {
                                                    changeSampleInputEditor({
                                                        open: true,
                                                        sampleIndex,
                                                    });
                                                }}
                                                deleteSample={(sampleIndex) => {
                                                    const newSamples = [...dataset.samples];
                                                    newSamples.splice(sampleIndex, 1);
                                                    onChangeDataset({
                                                        ...dataset,
                                                        samples: newSamples,
                                                    });
                                                }}
                                                onChangeFile={(file) => {
                                                    onChangeFile(file);
                                                    setValueDisplay(file.fileName);
                                                }}
                                                onChangeDataset={
                                                    async (newDataset) => {
                                                        onChangeDataset(newDataset);
                                                    }
                                                }
                                                authConfig={authConfig}
                                                user={user}
                                            />
                                        )}

                                        {mode === "label" && (
                                            <WorkspaceToolContext.Provider value={{ workspaceTool, setWorkspaceTool }}>
                                                <LabelView
                                                    selectedBrush={selectedBrush}
                                                    dataset={dataset}
                                                    projectStatus={projectStatus}
                                                    setProjectStatus={setProjectStatus}
                                                    saveProjectStatus={saveProjectStatus}
                                                    sampleTimeToComplete={sampleTimeToComplete}
                                                    onChangeSampleTimeToComplete={
                                                        changeSampleTimeToComplete
                                                    }
                                                    onChangeDataset={
                                                        (newDataset) => {
                                                            onChangeDataset(newDataset);
                                                        }
                                                    }
                                                    singleSampleDataset={singleSampleDataset}
                                                    onChangeSingleSampleDataset={
                                                        setSingleSampleDataset
                                                    }
                                                    onClickSetup={() => changeMode("setup")}
                                                    setBackCallback={setBackCallback}
                                                    // ENABLE IMAGE POINTS
                                                    // setInsufficientImagePointsError={setInsufficientImagePointsError}
                                                />
                                            </WorkspaceToolContext.Provider>
                                        )}
                                    </div>
                                    <EditSampleDialog
                                        open={sampleInputEditor.open}
                                        sampleIndex={sampleInputEditor.sampleIndex}
                                        sampleInput={
                                            sampleInputEditor.sampleIndex !== undefined
                                                ? dataset.samples[sampleInputEditor.sampleIndex]
                                                : null
                                        }
                                        onClose={() => {
                                            changeSampleInputEditor({ open: false });
                                        }}
                                        onChange={(newInput) => {
                                            onChangeDataset(
                                                setIn(
                                                    dataset,
                                                    ["samples", sampleInputEditor.sampleIndex],
                                                    newInput,
                                                ),
                                            );
                                        }}
                                    />
                                    {(projectStatus.iface.hasOwnProperty("type") && projectStatus.iface.type !== null) && (
                                        <Toolbar
                                            position="static"
                                        >
                                            <Box
                                                className={styles.arrowContainer}
                                                sx={{
                                                    display: "flex",
                                                    width: "100%",
                                                    flexDirection: "row",
                                                    // justifyContent: "space-around",
                                                }}
                                            >
                                                <NavStripButton
                                                    className={styles.arrowbutton}
                                                    key="Back"
                                                    variant="outlined"
                                                    onClick={onBack}
                                                >
                                                    <NavigateBefore />

                                                </NavStripButton>
                                                {mode !== headerTabs[headerTabs.length-1] && (
                                                    <NavStripButton
                                                        className={styles.arrowbutton}
                                                        key="Next"
                                                        variant="outlined"
                                                        onClick={onNext}
                                                    >

                                                        <NavigateNext />
                                                    </NavStripButton>
                                                )}
                                            </Box>
                                        </Toolbar>
                                    )}
                                </div>
                            </HotKeys>
                        )
                    }
                </>
            )}
        </UserContext.Provider>
        </LoadingContext.Provider>
    );
};

export default DatasetEditor;
